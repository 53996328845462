import type { Database } from '~/types/database.types'
import type { DbStockMetricsLight } from '~/types/stock.types'
import { fetchAll } from '~/utils/supabase'

// Managing the cache key
const stateKey = 'search-stock-metrics'

export const useSearchStockMetrics = () => {
  // Using lazy async data to not block initial page load
  return useLazyAsyncData<DbStockMetricsLight[]>(stateKey, async () => {
    console.log('Fetching light stock metrics for search')
    console.time('[TIME] fetching light stock metrics')

    try {
      const supabaseClient = useSupabaseClient<Database>()
      const query = supabaseClient
        .from('stock_metrics_new')
        .select('ticker_symbol,name,isins,logo_type')
        .order('market_cap', { ascending: false })

      const data = await fetchAll<DbStockMetricsLight>(query)
      return data
    } catch (error) {
      if (import.meta.client) {
        const customError = new DatabaseError(DatabaseErrorCodes.SELECT_STOCKS_IMPOSSIBLE, { props: { errorCause: error }})
        const { $Sentry } = useNuxtApp()
        $Sentry.captureException(customError)
      }
      return []
    } finally {
      console.timeEnd('[TIME] fetching light stock metrics')
    }
  }, {
    // Cache the data for the session
    immediate: true,
    server: false
  })
} 