<script setup lang="ts">
import type { DbStockMetricsLight } from '~/types/stock.types'
import { MagnifyingGlassIcon } from '@heroicons/vue/16/solid'

const props = withDefaults(defineProps<{
  theme?: 'light' | 'primary'
  isFreemium?: boolean
}>(), {
  theme: 'light',
  isFreemium: false
})

const emit = defineEmits<{
  'selectedTicker': [value: string]
  'notFreemiumAllowed': []
}>()

// Fetch all stock metrics and filter to allowed companies
const { data: lightStockMetrics } = useSearchStockMetrics()

// Keep track of top 10 companies by market cap
const top10Companies = computed(() => {
  if (!lightStockMetrics.value) return new Set<string>()
  return new Set(lightStockMetrics.value.slice(0, 10).map(stock => stock.ticker_symbol))
})

// Get the current ticker from the route
const route = useRoute()
const currentTicker = computed<string | undefined>(() => route.params?.stockMetricId as string | undefined)

// Selected company handling
const selectedCompany = ref<DbStockMetricsLight | null>(null)
const isNavigating = ref(false)
watch(selectedCompany, async (newCompany) => {
  if (newCompany) {
    // Check if company is allowed for freemium users
    if (props.isFreemium && !top10Companies.value.has(newCompany.ticker_symbol)) {
      emit('notFreemiumAllowed')
      selectedCompany.value = null
      return
    }

    // Check if company is the same as the current one
    if (currentTicker.value === newCompany.ticker_symbol) {
      selectedCompany.value = null
      return
    }

    isNavigating.value = true
    try {
      emit('selectedTicker', newCompany.ticker_symbol)
      await navigateTo(`${props.isFreemium ? '/public' : ''}/company/${newCompany.ticker_symbol}`)
    } catch (error) {
      console.error('Error navigating to company page:', error)
      isNavigating.value = false
      selectedCompany.value = null
    }
  }
})

// Reset selected company after page navigation is complete
const nuxtApp = useNuxtApp()
nuxtApp.hook('page:finish', () => {
  isNavigating.value = false
  selectedCompany.value = null
})

// Custom filter function to search across name and ticker
const filterFunction = (stock: DbStockMetricsLight, query: string): boolean => {
  const searchQuery = query.toLowerCase()
  const tickerMatch = stock.ticker_symbol.toLowerCase().includes(searchQuery)
  const nameMatch = stock.name.toLowerCase().includes(searchQuery)
  return tickerMatch || nameMatch
}

// Display function to show company name and ticker
const displayValue = (stock: DbStockMetricsLight | null) => {
  if (!stock) return ''
  return `${stock.name} (${stock.ticker_symbol.split(':')[1]})`
}
</script>

<template>
  <div class="w-full relative z-30">
    <VirtualCombobox
      v-model="selectedCompany"
      :items="lightStockMetrics || []"
      :display-value="displayValue"
      :filter-function="filterFunction"
      :estimate-size="50"
      :placeholder="lightStockMetrics === null ? 'Chargement des entreprises...' : 'Rechercher une entreprise'"
      :disabled="isNavigating || lightStockMetrics === null"
      :theme="theme"
      :has-prefix="true"
      :has-suffix="false"
      class="cursor-pointer"
    >
      <template #prefix>
        <MagnifyingGlassIcon class="h-5 w-5" :class="theme === 'light' ? 'text-gray-500' : 'text-sky-blue-100'" />
      </template>
      <template #option="{ item, isActive }">
        <div
          class="relative cursor-pointer select-none h-12 px-4 flex items-center"
          :class="{
            'bg-sky-blue-400 text-white': isActive,
            'text-gray-900': !isActive,
          }"
        >
          <StockInfos
            :stock="{
              ticker_symbol: item.ticker_symbol,
              name: item.name,
              logo_url: getIconLogoUrl(item.isins[0], item.logo_type),
            }"
            :show-logo="true"
            :show-exchange="true"
            :is-high="false"
          />
        </div>
      </template>
    </VirtualCombobox>
  </div>
</template> 