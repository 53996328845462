/**
 * Get the logo url of a stock from its ISIN and logo type
 *
 * @export
 * @param {string} isin
 * @param {(string | null)} logoType
 * @return {*}  {(string | undefined)}
 */
export function getIconLogoUrl(isin: string, logoType: string | null): string | undefined {
  if (logoType === null) {
    return undefined
  }

  return `https://yvtxcmafwqhioxonuwqm.supabase.co/storage/v1/object/public/logos/${isin}/icon.${logoType}`
}

/**
 * Get the exchange from the eodhdCode
 *
 * @export
 * @param {string} eodhdCode
 * @return {*}  {{
 *   name: string;
 *   eodhdCode: string;
 *   mic: string;
 *   currencyCode: string;
 *   countryISO2: string;
 * }}
 */
export function getExchangeFromEodhdCode(eodhdCode: string): {
  name: string;
  eodhdCode: string;
  mic: string;
  currencyCode: string;
  countryISO2: string;
} {
  // Special case for US as EODHD combines XNAS, XNYS and OTCM
  if (eodhdCode === "US") {
    return {
      name: "USA Stocks (NASDAQ, NYSE, OTC)",
      eodhdCode: "US",
      mic: "US",
      currencyCode: "USD",
      countryISO2: "US"
    }
  }

  // Getting the exchange from the eodhdCode
  const exchange = exchanges.find(exchange => exchange.eodhdCode === eodhdCode)
  if (exchange) {
    return exchange
  }

  // If the exchange is not found, return an unknown exchange
  return {
    name: `Unknown Exchange (${eodhdCode})`,
    eodhdCode: eodhdCode,
    mic: `Unknown (${eodhdCode})`,
    currencyCode: "Unknown",
    countryISO2: "Unknown"
  }
}

// List of exchanges
// From https://eodhd.com/api/exchanges-list/ and formatted for our use
export const exchanges = [
  {
    "name": "NASDAQ",
    "eodhdCode": "US",
    "mic": "XNAS",
    "currencyCode": "USD",
    "countryISO2": "US"
  },
  {
    "name": "New York Stock Exchange",
    "eodhdCode": "US",
    "mic": "XNYS",
    "currencyCode": "USD",
    "countryISO2": "US"
  },
  {
    "name": "OTC Markets",
    "eodhdCode": "US",
    "mic": "OTCM",
    "currencyCode": "USD",
    "countryISO2": "US"
  },
  {
    "name": "London Stock Exchange",
    "eodhdCode": "LSE",
    "mic": "XLON",
    "currencyCode": "GBP",
    "countryISO2": "GB"
  },
  {
    "name": "Toronto Stock Exchange",
    "eodhdCode": "TO",
    "mic": "XTSE",
    "currencyCode": "CAD",
    "countryISO2": "CA"
  },
  {
    "name": "TSX Venture Exchange",
    "eodhdCode": "V",
    "mic": "XTSX",
    "currencyCode": "CAD",
    "countryISO2": "CA"
  },
  {
    "name": "NEO Exchange",
    "eodhdCode": "NEO",
    "mic": "NEOE",
    "currencyCode": "CAD",
    "countryISO2": "CA"
  },
  {
    "name": "Euronext Paris",
    "eodhdCode": "PA",
    "mic": "XPAR",
    "currencyCode": "EUR",
    "countryISO2": "FR"
  },
  {
    "name": "Berlin Stock Exchange",
    "eodhdCode": "BE",
    "mic": "XBER",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "Hamburg Stock Exchange",
    "eodhdCode": "HM",
    "mic": "XHAM",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "XETRA Stock Exchange",
    "eodhdCode": "XETRA",
    "mic": "XETR",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "Dusseldorf Stock Exchange",
    "eodhdCode": "DU",
    "mic": "XDUS",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "Hanover Stock Exchange",
    "eodhdCode": "HA",
    "mic": "XHAN",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "Munich Stock Exchange",
    "eodhdCode": "MU",
    "mic": "XMUN",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "Stuttgart Stock Exchange",
    "eodhdCode": "STU",
    "mic": "XSTU",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "Frankfurt Stock Exchange",
    "eodhdCode": "F",
    "mic": "XFRA",
    "currencyCode": "EUR",
    "countryISO2": "DE"
  },
  {
    "name": "Luxembourg Stock Exchange",
    "eodhdCode": "LU",
    "mic": "XLUX",
    "currencyCode": "EUR",
    "countryISO2": "LU"
  },
  {
    "name": "Vienna Exchange",
    "eodhdCode": "VI",
    "mic": "XWBO",
    "currencyCode": "EUR",
    "countryISO2": "AT"
  },
  {
    "name": "Euronext Brussels",
    "eodhdCode": "BR",
    "mic": "XBRU",
    "currencyCode": "EUR",
    "countryISO2": "BE"
  },
  {
    "name": "Madrid Exchange",
    "eodhdCode": "MC",
    "mic": "BMEX",
    "currencyCode": "EUR",
    "countryISO2": "ES"
  },
  {
    "name": "SIX Swiss Exchange",
    "eodhdCode": "SW",
    "mic": "XSWX",
    "currencyCode": "CHF",
    "countryISO2": "CH"
  },
  {
    "name": "Euronext Lisbon",
    "eodhdCode": "LS",
    "mic": "XLIS",
    "currencyCode": "EUR",
    "countryISO2": "PT"
  },
  {
    "name": "Euronext Amsterdam",
    "eodhdCode": "AS",
    "mic": "XAMS",
    "currencyCode": "EUR",
    "countryISO2": "NL"
  },
  {
    "name": "Iceland Exchange",
    "eodhdCode": "IC",
    "mic": "XICE",
    "currencyCode": "ISK",
    "countryISO2": "IS"
  },
  {
    "name": "Irish Exchange",
    "eodhdCode": "IR",
    "mic": "XDUB",
    "currencyCode": "EUR",
    "countryISO2": "IE"
  },
  {
    "name": "Helsinki Exchange",
    "eodhdCode": "HE",
    "mic": "XHEL",
    "currencyCode": "EUR",
    "countryISO2": "FI"
  },
  {
    "name": "Oslo Stock Exchange",
    "eodhdCode": "OL",
    "mic": "XOSL",
    "currencyCode": "NOK",
    "countryISO2": "NO"
  },
  {
    "name": "Copenhagen Exchange",
    "eodhdCode": "CO",
    "mic": "XCSE",
    "currencyCode": "DKK",
    "countryISO2": "DK"
  },
  {
    "name": "Stockholm Exchange",
    "eodhdCode": "ST",
    "mic": "XSTO",
    "currencyCode": "SEK",
    "countryISO2": "SE"
  },
  {
    "name": "Victoria Falls Stock Exchange",
    "eodhdCode": "VFEX",
    "mic": "VFEX",
    "currencyCode": "ZWL",
    "countryISO2": "ZW"
  },
  {
    "name": "Zimbabwe Stock Exchange",
    "eodhdCode": "XZIM",
    "mic": "XZIM",
    "currencyCode": "ZWL",
    "countryISO2": "ZW"
  },
  {
    "name": "Lusaka Stock Exchange",
    "eodhdCode": "LUSE",
    "mic": "XLUS",
    "currencyCode": "ZMW",
    "countryISO2": "ZM"
  },
  {
    "name": "Uganda Securities Exchange",
    "eodhdCode": "USE",
    "mic": "XUGA",
    "currencyCode": "UGX",
    "countryISO2": "UG"
  },
  {
    "name": "Dar es Salaam Stock Exchange",
    "eodhdCode": "DSE",
    "mic": "XDAR",
    "currencyCode": "TZS",
    "countryISO2": "TZ"
  },
  {
    "name": "Prague Stock Exchange",
    "eodhdCode": "PR",
    "mic": "XPRA",
    "currencyCode": "CZK",
    "countryISO2": "CZ"
  },
  {
    "name": "Rwanda Stock Exchange",
    "eodhdCode": "RSE",
    "mic": "RSEX",
    "currencyCode": "RWF",
    "countryISO2": "RW"
  },
  {
    "name": "Botswana Stock Exchange",
    "eodhdCode": "XBOT",
    "mic": "XBOT",
    "currencyCode": "BWP",
    "countryISO2": "BW"
  },
  {
    "name": "Egyptian Exchange",
    "eodhdCode": "EGX",
    "mic": "NILX",
    "currencyCode": "EGP",
    "countryISO2": "EG"
  },
  {
    "name": "Nigerian Stock Exchange",
    "eodhdCode": "XNSA",
    "mic": "XNSA",
    "currencyCode": "NGN",
    "countryISO2": "NG"
  },
  {
    "name": "Ghana Stock Exchange",
    "eodhdCode": "GSE",
    "mic": "XGHA",
    "currencyCode": "GHS",
    "countryISO2": "GH"
  },
  {
    "name": "Malawi Stock Exchange",
    "eodhdCode": "MSE",
    "mic": "XMSW",
    "currencyCode": "MWK",
    "countryISO2": "MW"
  },
  {
    "name": "Regional Securities Exchange",
    "eodhdCode": "BRVM",
    "mic": "XBRV",
    "currencyCode": "XOF",
    "countryISO2": "CI"
  },
  {
    "name": "Nairobi Securities Exchange",
    "eodhdCode": "XNAI",
    "mic": "XNAI",
    "currencyCode": "KES",
    "countryISO2": "KE"
  },
  {
    "name": "Casablanca Stock Exchange",
    "eodhdCode": "BC",
    "mic": "XCAS",
    "currencyCode": "MAD",
    "countryISO2": "MA"
  },
  {
    "name": "Stock Exchange of Mauritius",
    "eodhdCode": "SEM",
    "mic": "XMAU",
    "currencyCode": "MUR",
    "countryISO2": "MU"
  },
  {
    "name": "Tel Aviv Stock Exchange",
    "eodhdCode": "TA",
    "mic": "XTAE",
    "currencyCode": "ILS",
    "countryISO2": "IL"
  },
  {
    "name": "KOSDAQ",
    "eodhdCode": "KQ",
    "mic": "XKOS",
    "currencyCode": "KRW",
    "countryISO2": "KR"
  },
  {
    "name": "Korea Stock Exchange",
    "eodhdCode": "KO",
    "mic": "XKRX",
    "currencyCode": "KRW",
    "countryISO2": "KR"
  },
  {
    "name": "Budapest Stock Exchange",
    "eodhdCode": "BUD",
    "mic": "XBUD",
    "currencyCode": "HUF",
    "countryISO2": "HU"
  },
  {
    "name": "Warsaw Stock Exchange",
    "eodhdCode": "WAR",
    "mic": "XWAR",
    "currencyCode": "PLN",
    "countryISO2": "PL"
  },
  {
    "name": "Philippine Stock Exchange",
    "eodhdCode": "PSE",
    "mic": "XPHS",
    "currencyCode": "PHP",
    "countryISO2": "PH"
  },
  {
    "name": "Jakarta Exchange",
    "eodhdCode": "JK",
    "mic": "XIDX",
    "currencyCode": "IDR",
    "countryISO2": "ID"
  },
  {
    "name": "Australian Securities Exchange",
    "eodhdCode": "AU",
    "mic": "XASX",
    "currencyCode": "AUD",
    "countryISO2": "AU"
  },
  {
    "name": "Shanghai Stock Exchange",
    "eodhdCode": "SHG",
    "mic": "XSHG",
    "currencyCode": "CNY",
    "countryISO2": "CN"
  },
  {
    "name": "Karachi Stock Exchange",
    "eodhdCode": "KAR",
    "mic": "XKAR",
    "currencyCode": "PKR",
    "countryISO2": "PK"
  },
  {
    "name": "Johannesburg Exchange",
    "eodhdCode": "JSE",
    "mic": "XJSE",
    "currencyCode": "ZAR",
    "countryISO2": "ZA"
  },
  {
    "name": "National Stock Exchange of India",
    "eodhdCode": "NSE",
    "mic": "XNSE",
    "currencyCode": "INR",
    "countryISO2": "IN"
  },
  {
    "name": "Athens Exchange",
    "eodhdCode": "AT",
    "mic": "ASEX",
    "currencyCode": "EUR",
    "countryISO2": "GR"
  },
  {
    "name": "Shenzhen Stock Exchange",
    "eodhdCode": "SHE",
    "mic": "XSHE",
    "currencyCode": "CNY",
    "countryISO2": "CN"
  },
  {
    "name": "Chilean Stock Exchange",
    "eodhdCode": "SN",
    "mic": "XSGO",
    "currencyCode": "CLP",
    "countryISO2": "CL"
  },
  {
    "name": "Thailand Exchange",
    "eodhdCode": "BK",
    "mic": "XBKK",
    "currencyCode": "THB",
    "countryISO2": "TH"
  },
  {
    "name": "Colombo Stock Exchange",
    "eodhdCode": "CM",
    "mic": "XCOL",
    "currencyCode": "LKR",
    "countryISO2": "LK"
  },
  {
    "name": "Vietnam Stocks",
    "eodhdCode": "VN",
    "mic": "HSTC",
    "currencyCode": "VND",
    "countryISO2": "VN"
  },
  {
    "name": "Kuala Lumpur Exchange",
    "eodhdCode": "KLSE",
    "mic": "XKLS",
    "currencyCode": "MYR",
    "countryISO2": "MY"
  },
  {
    "name": "Bucharest Stock Exchange",
    "eodhdCode": "RO",
    "mic": "XBSE",
    "currencyCode": "RON",
    "countryISO2": "RO"
  },
  {
    "name": "Sao Paulo Exchange",
    "eodhdCode": "SA",
    "mic": "BVMF",
    "currencyCode": "BRL",
    "countryISO2": "BR"
  },
  {
    "name": "Buenos Aires Exchange",
    "eodhdCode": "BA",
    "mic": "XBUE",
    "currencyCode": "ARS",
    "countryISO2": "AR"
  },
  {
    "name": "Mexican Exchange",
    "eodhdCode": "MX",
    "mic": "XMEX",
    "currencyCode": "MXN",
    "countryISO2": "MX"
  },
  {
    "name": "London IL",
    "eodhdCode": "IL",
    "mic": "XLON",
    "currencyCode": "USD",
    "countryISO2": "GB"
  },
  {
    "name": "Zagreb Stock Exchange",
    "eodhdCode": "ZSE",
    "mic": "XZAG",
    "currencyCode": "EUR",
    "countryISO2": "HR"
  },
  {
    "name": "Taiwan Stock Exchange",
    "eodhdCode": "TW",
    "mic": "XTAI",
    "currencyCode": "TWD",
    "countryISO2": "TW"
  },
  {
    "name": "Taiwan OTC Exchange",
    "eodhdCode": "TWO",
    "mic": "ROCO",
    "currencyCode": "TWD",
    "countryISO2": "TW"
  },
  {
    "name": "Bolsa de Valores de Lima",
    "eodhdCode": "LIM",
    "mic": "XLIM",
    "currencyCode": "PEN",
    "countryISO2": "PE"
  },
  {
    "name": "Istanbul Stock Exchange",
    "eodhdCode": "IS",
    "mic": "XIST",
    "currencyCode": "TRY",
    "countryISO2": "TR"
  }
]