import type { Database } from '~/types/database.types'
import type { PostgrestFilterBuilder } from '@supabase/postgrest-js'

/**
 * Extends a Supabase query to fetch all rows with pagination
 */
export const fetchAll = async <T>(
  query: PostgrestFilterBuilder<Database['public'], any, T[]>,
  options?: { batchSize?: number }
) => {
  const batchSize = options?.batchSize ?? 1000
  const maxBatches = 100 // Safety limit
  
  const results: T[] = []
  let hasMore = true
  let page = 0
  let batchCount = 0
  
  console.time(`[TIME] fetching all rows with pagination`)
  
  try {
    while (hasMore && batchCount < maxBatches) {
      const { data: batch, error } = await query
        .range(page * batchSize, (page + 1) * batchSize - 1)
      
      if (error) {
        console.error('Error fetching batch:', error)
        throw error
      }
      
      if (!batch || batch.length === 0) {
        hasMore = false
        break
      }
      
      results.push(...batch)
      
      if (batch.length < batchSize) {
        hasMore = false
      } else {
        page++
        batchCount++
      }
    }
    
    return results
  } finally {
    console.timeEnd(`[TIME] fetching all rows with pagination`)
    console.log(`Fetched ${results.length} rows in ${batchCount + 1} batches`)
  }
} 